import { Component, OnInit, Inject } from '@angular/core';
import { TIERMessages, TIERAuth, TIERAPICalls, TIERToast } from '../../../services';

import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tiernews',
    template: ` <div id="news" class="container-fluid pb-2" [hidden]="news.Messages.length <= 0">
        <div class="card mb-2 alert-warning" *ngFor="let Message of news.Messages">
            <div class="card-header">
                <span *ngIf="Message.MessageTypeName==='NEWS'">News</span>
                <span *ngIf="Message.MessageTypeName==='SYSTEM'">System Message</span>
                <button type="button" class="float-end close-button" (click)="confirm(Message.Id)"><span>&times;</span>
                </button>
            </div>
            <div class="card-body"  [ngClass]="{ 'text-bg-warning': Message.MessageTypeName === 'NEWS', 'text-bg-info': Message.MessageTypeName === 'SYSTEM' }">
                {{Message.Text}}
                <div class="float-end" *ngIf="Message.MessageTypeName==='SYSTEM'"><br /><br />
                    <button type="button" class="btn btn-primary" (click)="confirm(Message.Id)">Confirm</button>
                </div>
            </div>
        </div>
    </div>`,
    styleUrls: [ "./TIERNews.component.scss" ]
})
export class TIERNewsComponent implements OnInit {
    constructor(@Inject(TIERMessages) public news : TIERMessages,
                @Inject(TIERAuth) private auth : TIERAuth,
                @Inject(TIERAPICalls) private apicall : TIERAPICalls,
                @Inject(TIERToast) private alert : TIERToast)
    {
        this.auth.LoginEvent.subscribe({
            next: () => {
                this.news.RefreshEvent.emit();
            }
        });

        this.auth.LogoutEvent.subscribe({
            next: () => {
                this.news.Clear();
            }
        })
    }

    ngOnInit() : void {
        if(this.auth.isLoggedIn())
            this.news.RefreshEvent.emit();
    }

    public confirm(id : number | undefined) : void {
        if(!id)
            return;

        this.apicall.put('/api/messages/setread/%s'.formUri(id), null).subscribe({
            next: () => {
                this.news.DeleteMessage(id);
            },
            error: (error) => {
                this.news.DeleteMessage(id);
                this.alert.error(http2Error(error));
            }
        });
    }
}
