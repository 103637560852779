<div class="hstack mb-3">
    <div class="ms-auto">
        <input type="text"
            class="form-control"
            placeholder="Search..."
            [(ngModel)]="searchText">
    </div>
</div>

<div class="table-responsive">
    <table class="table" [hidden]="!sessions.length">
        <thead>
            <tr>
                <th class="text-center">Attend</th>
                <th>Scheduled</th>
                <th>Type</th>
                <th class="text-center">Course</th>
                <th>Modules</th>
                <th>Training Content</th>
                <th>Attendance</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let session of sessions | search: searchText">
                <td class="text-center">
                    <button type="button" class="btn btn-primary btn-xs" (click)="attend(session.Id, session.IsAttending)" [disabled]="session.IsFaciliator">
                        <i class="fa-solid fa-user-plus" *ngIf="!session.IsAttending"></i>
                        <i class="fa-solid fa-times" *ngIf="session.IsAttending"></i>
                    </button>
                </td>
                <td>
                    <tier-telltime [from]="session.StartDate" [to]="session.EndDate"></tier-telltime>
                </td>
                <td>
                    {{session.Sessiontype?.Name ?? 'N/A'}}
                </td>
                <td class="text-center">
                    <div class="mb-3">
                        <span *ngIf="session.URL"><a href="{{session.URL}}" target="_blank">{{session.Title}}</a></span>
                        <span *ngIf="!session.URL">{{session.Title}}</span>
                    </div>
                    <div class="mb-3 fw-bold">
                        At
                    </div>
                    <div class="mb-3">
                        <tier-mapping-button [location]="session.Venue" permission="r" [disabled]="!session?.Venue" [classes]="['btn-sm', 'btn-info']">
                            <i class="fa-solid fa-map"></i> {{session?.Venue?.Name || "N/A"}}
                        </tier-mapping-button>
                    </div>
                    <div class="mb-3 fw-bold">
                        Faciliated By
                    </div>
                    <div class="mb-3">
                        <div *ngFor="let facilitator of session.Facilitators">
                            <span class="badge text-bg-info">{{facilitator}}</span>
                        </div>
                        <span *ngIf="!session?.Facilitators?.length" class="badge text-bg-danger">No one</span>
                    </div>
                </td>
                <td>
                    <div *ngFor="let skillModule of session.SkillModules">
                        <tier-skilltooltip [skill]="skillModule"></tier-skilltooltip>
                    </div>
                    <span class="badge text-bg-secondary" *ngIf="session.SkillModules?.length === 0">
                        No Modules
                    </span>
                </td>
                <td>{{session.TrainingContent || 'N/A'}}</td>
                <td>
                    <tier-teammembersbadge [teammembers]="session.Attendees" bindname="FullName"></tier-teammembersbadge>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="alert alert-info" role="alert" [hidden]="sessions.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>

