<div class="d-print-none mb-3 d-flex justify-content-end" autocomplete="none">
    <div class="form-group">
        <input type="text" class="form-control"
               placeholder="Search..."
               [(ngModel)]="search"
               minlength="2"
               maxlength="20">
    </div>
</div>
<div *ngIf="report.length > 0">
    <table class="table">
        <thead TIERCustomTableHeader [format]="[
                                    { id: 'TeammemberName', name: 'Team Member' },
                                    { id: 'Missed', name: 'Missed Sessions' },
                                    { id: 'Facilitated', name: 'Facilitated Sessions' },
                                    { id: 'Attended', name: 'Attended Sessions' }]"
               [(table)]="report">
        </thead>
        <tbody>
            <tr *ngFor="let at of report | search: search">
                <td>{{at.TeammemberName}}</td>
                <td>{{at.Missed}}</td>
                <td>{{at.Facilitated}}</td>
                <td>{{at.Attended}}</td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center my-3 d-print-none" *ngIf="params.Amount">
        <ngb-pagination [collectionSize]="total" [(page)]="params.Page" [pageSize]="params.Amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="reload()" />
    </div>
</div>

<div class="alert alert-warning" role="alert" [hidden]="report.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>

<TIERReportButtons (print)="print()" (export)="export('TrainingAttendenceReport')"></TIERReportButtons>
