import { Component, Input, forwardRef, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TIERAPICalls, TIERToast } from '../../../services';
import { TIERTypeDropdownModalComponent } from './TIERTypeDropdownModal.component';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tiertypedropdown',
    template: `
        <div class="hstack">
            <div class="w-100 me-1">
                <tierdropdown
                    [source]="url"
                    [placeholder]="placeholder"
                    [refresh]="refreshEmitter"
                    [(ngModel)]="selectedEntry"
                    (ngModelChange)="onChange($event)"
                    [allowNew]=false
                    [bindId]="bindId"
                    bindValue="Name"
                    [multi]="multi"
                    [disabled]="disabled"
                    [clearable]="clearable">
                </tierdropdown>
            </div>
            <div class="ms-auto">
                <div class="hstack">
                    <div class="me-1">
                        <button type="button" class="btn btn-primary" (click)="add()" [disabled]="disabled"><i class="fa fa-plus"></i></button>
                    </div>
                    <div *ngIf="allowdelete">
                        <button type="button" class="btn btn-secondary" (click)="delete()" [disabled]="!selectedEntry || disabled"><i class="fa fa-trash"></i></button>
                    </div>
                </div>
            </div>
        </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TIERTypeDropdownComponent),
        multi: true
    }]
})
export class TIERTypeDropdownComponent implements ControlValueAccessor {

    @Input({ required: true }) url! : string;
    @Input() label : string = "None";
    @Input() allowdelete : boolean = true;
    @Input() placeholder : string = '';
    @Input() multi : boolean = false;
    @Input() bindId : any = null;
    @Input() deleteId : any = null;
    @Input() clearable : boolean = false;

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    public refreshEmitter : EventEmitter<string | number> = new EventEmitter();
    public selectedEntry : any = [];
    public disabled : boolean = false;

    constructor(@Inject(NgbModal) private modalService : NgbModal,
                @Inject(TIERAPICalls) private apicall : TIERAPICalls,
                @Inject(TIERToast) private alert : TIERToast)
        {}

    public add() {
        const modalRef = this.modalService.open(TIERTypeDropdownModalComponent);

        modalRef.componentInstance.label = this.label;

        modalRef.result.then((result : any) => {
            this.apicall.save(this.url, { Name: result }).subscribe({
                next: (response : any) => {
                    this.selectedEntry = this.multi ? [ this.bindId ? response[this.bindId] : response ] : response.Id;
                    this.updateChanges(response.Id);
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
        }).catch(() => {});
    }

    private updateChanges(entry : any) : void {
        this.onChange(entry);
        this.refreshEmitter.emit(entry);
    }

    private _delete(id : number | undefined) {
        if(!id)
            return;

        this.apicall.delete(this.url + '/%s'.formUri(id)).subscribe({
            next: () => {
                this.updateChanges(undefined);
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public delete() {
        if(Array.isArray(this.selectedEntry)) {
            this.selectedEntry.forEach((entry : any) =>
                this._delete(this.deleteId ? entry[this.deleteId] : entry)
            )
        } else {
            this._delete(this.deleteId ? this.selectedEntry[this.deleteId] : this.selectedEntry);
        }
    }

    writeValue(id : any): void {
        this.selectedEntry = id;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
