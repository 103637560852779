import { forwardRef, Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

import { isNorU } from 'src/tier/tier.utils';

@Directive({
  selector: '[InvalidUsers]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InvalidUsersValidationDirective),
      multi: true,
    },
  ]
})
export class InvalidUsersValidationDirective implements Validator {
    onChange: () => void = () => {};

    validate(control: AbstractControl): ValidationErrors | null {
        if(isNorU(control?.value) || !Array.isArray(control?.value))
            return null;

        let invalid : string[] = [];
        for(let i=0; i<control.value.length; i++) {
            if(control?.value[i]?.LockoutEnabled === true)
                invalid.push(control?.value[i]?.FullName ?? 'Unknown');
        }

        if(invalid.length > 0)
            return { 'InvalidUsers': invalid };

        return null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }
}
