<div class="col-md-8 offset-md-2">
    <div class="card mb-3">
        <div class="card-header">
            Select Report
        </div>
        <div class="card-body">
            <div class="row">
                <label for="repdd" class="col-sm-3 col-form-label fw-bold">Report</label>
                <div class="col-sm-9">
                    <tierdropdown
                        name="repdd"
                        id="repdd"
                        [source]="Reports"
                        placeholder="Select report"
                        [(ngModel)]="SelectedReportId"
                        (ngModelChange)="getMetaData($event)"
                        bindId="Id"
                        bindValue="Name">
                    </tierdropdown>
                </div>
            </div>
        </div>
    </div>

    <div [hidden]="!ReportMetaData?.Description">
        <div class="card mb-3">
            <div class="card-body">
                <span [innerHTML]="ReportMetaData?.Description"></span>
            </div>
        </div>
    </div>

    <div class="card mb-3" [hidden]="!ReportMetaData">
        <div class="card-header">
            {{ReportMetaData?.Name}} Options
        </div>
        <div class="card-body">
            <form role="form" #reportOptionsForm="ngForm" name="reportOptionsForm" novalidate autocomplete="none">
                <div class="row mb-3">
                    <label for="sortdd" class="col-sm-3 col-form-label fw-bold">Sort By</label>
                    <div class="col-sm-9">
                        <tierdropdown
                            id="sortdd"
                            name="sortdd"
                            [source]="ReportMetaData?.Columns ?? []"
                            placeholder="Select Column"
                            [(ngModel)]="ReportOptions.Column"
                            bindId="Id"
                            bindValue="Name"
                            required>
                        </tierdropdown>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="orderdd" class="col-sm-3 col-form-label fw-bold">Order</label>
                    <div class="col-sm-9">
                        <tierdropdown
                            id="orderdd"
                            name="orderdd"
                            [source]="OrderOption"
                            placeholder="Select Order"
                            [(ngModel)]="ReportOptions.IsAsc"
                            bindId="Id"
                            bindValue="Name"
                            required>
                        </tierdropdown>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="ippdd" class="col-sm-3 col-form-label fw-bold">Items Per Page</label>
                    <div class="col-sm-9">
                        <tierdropdown
                            id="ippdd"
                            name="ippdd"
                            [source]="AmountOption"
                            placeholder="Select Amount"
                            [(ngModel)]="ReportOptions.Amount"
                            bindId="Id"
                            bindValue="Name"
                            required>
                        </tierdropdown>
                    </div>
                </div>
                <div *ngFor="let option of ReportMetaData?.Options">
                    <div class="row mb-3" *ngIf="option?.Type === 'date'">
                        <label class="col-sm-3 col-form-label fw-bold" [textContent]="option?.Label"></label>
                        <div class="col-sm-9">
                            <tiertimepicker [name]="option.Id!" [(ngModel)]="ReportOptions[option.Id!]"></tiertimepicker>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="option?.Type === 'text'">
                        <label class="col-sm-3 col-form-label fw-bold" [textContent]="option?.Label"></label>
                        <div class="col-sm-9">
                            <input type="text" [name]="option.Id!" class="form-control" [(ngModel)]="ReportOptions[option.Id!]">
                        </div>
                    </div>
                   <div class="row mb-3" *ngIf="option?.Type === 'number'">
                        <label class="col-sm-3 col-form-label fw-bold" [textContent]="option?.Label"></label>
                        <div class="col-sm-9">
                            <input type="number" [name]="option.Id!" class="form-control" [(ngModel)]="ReportOptions[option.Id!]">
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="option?.Type === 'teammembers'">
                        <label class="col-sm-3 col-form-label fw-bold" [textContent]="option?.Label"></label>
                        <div class="col-sm-9">
                            <tierdropdown
                                [name]="option.Id"
                                source="api/teammembers"
                                placeholder="Select Team Member"
                                [(ngModel)]="ReportOptions[option.Id!]"
                                bindId="Id"
                                bindValue="FullName"
                                [multi]="true">
                            </tierdropdown>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="option?.Type === 'roles'">
                        <label class="col-sm-3 col-form-label fw-bold" [textContent]="option?.Label"></label>
                        <div class="col-sm-9">
                            <tierdropdown
                                [name]="option.Id"
                                source="api/roles"
                                placeholder="Select Roles"
                                [(ngModel)]="ReportOptions[option.Id!]"
                                bindId="Id"
                                bindValue="Name"
                                [multi]="true">
                            </tierdropdown>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="option?.Type === 'skills'">
                        <label class="col-sm-3 col-form-label fw-bold" [textContent]="option?.Label"></label>
                        <div class="col-sm-9">
                            <tierdropdown
                                [name]="option.Id"
                                source="api/modules"
                                placeholder="Select Skills"
                                [(ngModel)]="ReportOptions[option.Id!]"
                                bindId="Id"
                                bindValue="Name"
                                [objectList]="true"
                                [multi]="true">
                            </tierdropdown>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="option?.Type === 'dropdown'">
                        <label class="col-sm-3 col-form-label fw-bold" [textContent]="option?.Label"></label>
                        <div class="col-sm-9">
                            <tierdropdown
                                [name]="option.Id"
                                [source]="option.Values ?? null"
                                [placeholder]="'Select ' + option.Label"
                                [(ngModel)]="ReportOptions[option.Id!]"
                                bindId="Id"
                                bindValue="Name">
                            </tierdropdown>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="option?.Type === 'sessiontype'">
                            <label class="col-sm-3 col-form-label fw-bold" [textContent]="option?.Label"></label>
                            <div class="col-sm-9">
                                <tierdropdown
                                    [name]="option.Id"
                                    source="api/sessiontype"
                                    [placeholder]="'Select ' + option.Label"
                                    [(ngModel)]="ReportOptions[option.Id!]"
                                    bindId="Id"
                                    bindValue="Name"
                                    [clearable]="true">
                                </tierdropdown>
                            </div>
                        </div>
                   <div class="row mb-2" *ngIf="option?.Type === 'radio'">
                        <label class="col-sm-3 pt-0 col-form-label fw-bold" [textContent]="option?.Label"></label>
                        <div class="col-sm-9">
                            <div class="form-check" *ngFor="let value of option.Values">
                                <input class="form-check-input" [name]="option.Id!" type="radio" [value]="value.Id" [(ngModel)]="ReportOptions[option.Id!]">
                                <label class="form-check-label" [textContent]="value.Name"></label>
                            </div>
                        </div>
                    </div>
                   <div class="row mb-2" *ngIf="option?.Type === 'tickbox'">
                        <div class="col-sm-9 offset-sm-3">
                            <div class="form-check">
                                <input class="form-check-input" [name]="option.Id!" type="checkbox" [(ngModel)]="ReportOptions[option.Id!]">
                                <label class="form-check-label" [textContent]="option.Label"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hstack">
                    <div class="ms-auto">
                        <button type="button" class="btn btn-secondary me-2" (click)="export()" [disabled]="reportOptionsForm.invalid">
                            Export
                        </button>
                        <button type="button" class="btn btn-primary" (click)="generate()" [disabled]="reportOptionsForm.invalid">
                            Generate
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>


