import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { NgbCollapseModule, NgbPaginationModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

import { routes } from './reports.routes';
import { SharedModule } from '../shared/shared.module';

import {
    TIERCustomTableHeaderComponent,
    TIERReportButtonsComponent,
    TIERExtendButtonsComponent,
    TIERReportPaneComponent,
    IncidentReportComponent,
    IncidentAttendenceReportComponent,
    IncidentInformationReportComponent,
    ModuleTimelineReportComponent,
    RoleReportComponent,
    TrainingReportComponent,
    EquipmentReportComponent,
    UserReportComponent,
    EquipmentUsageReportComponent,
    TrainingAttendenceReportComponent
} from './components';

@NgModule({
    declarations: [
        TIERCustomTableHeaderComponent,
        TIERReportButtonsComponent,
        TIERExtendButtonsComponent,
        TIERReportPaneComponent,
        IncidentReportComponent,
        IncidentAttendenceReportComponent,
        IncidentInformationReportComponent,
        ModuleTimelineReportComponent,
        RoleReportComponent,
        TrainingReportComponent,
        EquipmentReportComponent,
        UserReportComponent,
        EquipmentUsageReportComponent,
        TrainingAttendenceReportComponent
    ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        NgbCollapseModule,
        NgbPaginationModule,
        NgbProgressbarModule,
        SharedModule,
        FormsModule,
        UIRouterModule.forChild({ states: routes }),
    ],
    providers: [],
    exports: [
    ]
})
export class ReportsModule {
    constructor() {}
}
