import { Component, Inject } from '@angular/core';

import { TrainingAttendencePagenationModel, TrainingAttendenceReportModel } from '../../models';
import { Reports } from '../../reports';
import { TIERAPICalls } from '../../../services/';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-reports-trainingattendencereport',
    templateUrl: './trainingattendencereport.template.html'
})
export class TrainingAttendenceReportComponent extends Reports {
    public report : TrainingAttendenceReportModel[] = [];
    public total : number = 0;
    public search : string = '';

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls
    ) { super(); };

    public get() {
        this.apicall.post<TrainingAttendencePagenationModel>('api/reports/TrainingAttendenceReport',
            this.state2Options()
        ).subscribe({
            next: (response : TrainingAttendencePagenationModel) => {
                this.report = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public setState(state : boolean) {}
}
